import { Auth } from 'aws-amplify';
import PNotify from "pnotify/dist/es/PNotify";
import "pnotify/dist/es/PNotifyButtons";
import "pnotify/dist/es/PNotifyConfirm";
import "pnotify/dist/es/PNotifyCallbacks";
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

// https://stackoverflow.com/questions/105034/
function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

if (typeof window.stackBottomRight === 'undefined') {
    window.stackBottomRight = {
        'dir1': 'up',
        'dir2': 'left',
        'firstpos1': 25,
        'firstpos2': 25
    };
}

const getToken = () => new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
        user.getSession((err, session) => {
            if (err) {
                alert('Une erreur inconnue est survenue.');
                console.log(err);
            } else {
                resolve(session.getIdToken().getJwtToken());
            }
        });
    }).catch(reject);
});

const getData = (path = '/users') => new Promise( resolve => {
    getToken().then(token => {
        axios.get(window.API_GATEWAY_ENDPOINT + path, {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('Une erreur inconnue est survenue.');
            console.log(err);
        });
    }).catch(err => {
        alert('Une erreur inconnue est survenue.');
        console.log(err);
    });
});

const postData = (path = '/users', data) => new Promise(resolve => {
    getToken().then(token => {
        axios.post(window.API_GATEWAY_ENDPOINT + path, data, {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('Une erreur inconnue est survenue.');
            console.log(err);
        });
    }).catch(err => {
        alert('Une erreur inconnue est survenue.');
        console.log(err);
    })
});

const putData = (path = '/users', data) => new Promise(resolve => {
    getToken().then(token => {
        axios.put(window.API_GATEWAY_ENDPOINT + path, data, {
            headers: { Authorization: token }
        }).then(resolve).catch(err => {
            alert('Une erreur inconnue est survenue.');
            console.log(err);
        });
    }).catch(err => {
        alert('Une erreur inconnue est survenue.');
        console.log(err);
    })
});

const deleteData = (path = '/users', dataId) => new Promise(resolve => {
    getToken().then(token => {
        axios.delete(window.API_GATEWAY_ENDPOINT + path + '?id=' + dataId, {
            headers: {Authorization: token }
        }).then(resolve).catch(err => {
            alert('Une erreur inconnue est survenue.');
            console.log(err);
        });
    }).catch(err => {
        alert('Une erreur inconnue est survenue.');
        console.log(err);
    })
});

const refreshUsers = () => new Promise(async resolve => {
    const cognito_users = await getData();
    try {
        window.COGNITO_USERS = cognito_users.data.response.success.Users.map(u => ({
            sub: u.Attributes.find(a => a.Name === 'sub').Value,
            email: u.Attributes.find(a => a.Name === 'email').Value,
            firstname: u.Attributes.find(a => a.Name === 'custom:firstname').Value,
            lastname: u.Attributes.find(a => a.Name === 'custom:lastname').Value,
        }));
        window.EXCURSIONS = cognito_users.data.response.success.Excursions.map(e => ({
            excursionDelay: e.excursionDelay.S,
            cognitoUser: e.cognitoUser.S,
            excursionName: e.excursionName.S,
            id: e.id.S,
            maxParticipantsNumber: e.maxParticipantsNumber ? e.maxParticipantsNumber.S : '-',
            participants: e.participants.L.map(({ M }) => ({
                dateAdd: (M.dateAdd ? M.dateAdd.S : ''),
                disabled: (M.disabled ? M.disabled.S : ''),
                email: (M.email ? M.email.S : ''),
                emergencyContact: (M.emergencyContact ? M.emergencyContact.S : ''),
                firstname: (M.firstname ? M.firstname.S : ''),
                lastname: (M.lastname ? M.lastname.S : ''),
                phone: (M.phone ? M.phone.S : '')
            }))
        }));
    } catch (e) {
        console.log(e);
        window.COGNITO_USERS = [];
        window.EXCURSIONS = [];
    }
    resolve();
});

export default {
    getToken,
    getData,
    postData,
    putData,
    deleteData,
    refreshUsers,
    capitalizeFirstLetter: s => s.charAt(0).toUpperCase() + s.slice(1),
    isAuthenticated: () => new Promise(resolve => {
        Auth.currentAuthenticatedUser().then(user => resolve(true)).catch(err => resolve(false));
    }),
    getFirstname: () => Auth.user.attributes['custom:firstname'],
    getLastname: () => Auth.user.attributes['custom:lastname'],
    getEmail: () => Auth.user.attributes.email,
    getUserId: () => Auth.user.attributes.sub,
    notify: PNotify,
    logout: () => new Promise(resolve => {
        Auth.signOut().then(resolve).catch(err => console.log(err));
    }),
    signIn: (email, password) => Auth.signIn({
        username: email,
        password: password
    }),
    randomUUID: uuidv4,
    encodeStr: rawStr => rawStr.replace(/[\u00A0-\u9999<>\&]/gim, i => ('&#' + i.charCodeAt(0) + ';')),
    decodeStr: encodedStr => window.jQuery("<div/>").html(encodedStr).text()
}
